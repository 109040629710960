@import "../../../node_modules/bootstrap/scss/functions";
@import "./variables-theme";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "button-themes";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

//$main-bg-color: #1ABC9C; //#4060b6
$main-bg-color: #4060b6;

@import "nav";
@import "navbar";
@import "navmenu";
@import "header";

body {
  position: relative;
  //margin-top: 78px;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: 300;
  //font-family: 'M PLUS Rounded 1c', sans-serif;
  font-family: 'Varela Round', sans-serif;
  letter-spacing: .05em;

}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}

.tech-images {
  @include media-breakpoint-down(xl) {
    max-height: 400px;
    margin-bottom: 1rem !important;
    margin-top: 3rem !important;
  }
}

.heading-3 {
  font-size: 42px;
  line-height: 1.19048;
}

header {
  min-height: 800px;
  margin-bottom: 70px;
  position: relative;
  //margin-top: 78px;

  &::before {
    content: '';
    background-color: $main-bg-color;
    background-image: linear-gradient(to right, rgba(103, 210, 255, 0.27), rgba(38, 27, 85, 0.27));
    background-repeat: no-repeat;
    background-position-y: 0;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .container {
    &.content {
      padding-top: 84px;
      //height: 684px;
      //height: 530px;

      @include media-breakpoint-down(lg) {
        padding-top: 3rem;
      }

      h1 {
        font-size: 5rem;
        @include media-breakpoint-down(lg) {
          font-size: 3rem;
        }
      }

      h2 {
        font-size: 3rem;
        @include media-breakpoint-down(lg) {
          font-size: 1.5rem;
        }
      }
    }
  }

  #img-header {
    @include media-breakpoint-down(xl) {
      max-height: 400px;
    }
  }
}

.nectar-shape-divider-wrap {
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
  transform: translateZ(0);
  margin-top: -80px;
}

section {
  padding-bottom: 30px;
}

#customers {
  background-color: #f2f3f8;
  img {
    //transition: filter 0.45s cubic-bezier(0.3, 0.4, 0.1, 1), opacity 0.45s cubic-bezier(0.3, 0.4, 0.1, 1);
    //-webkit-filter: grayscale(100%);
    //filter: grayscale(100%);
    //opacity: 0.55;

    &:hover {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      opacity: 1;
    }
  }
}

#about {
  //background-image: url("assets/images/bg-team.jpg");
  //background-repeat: no-repeat;
  //background-position-y: 0;
  //background-size: cover;

  h3 {
    font-size: 14px;
    font-weight: 700;
    color: #4154f1;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #012970;
  }

  p {
    margin: 15px 0 30px 0;
    line-height: 24px;
  }
}

#contact {
  position: relative;

  &::before {
    content: '';
    background-color: $main-bg-color;
    background-image: linear-gradient(to right, rgba(103, 210, 255, 0.27), rgba(38, 27, 85, 0.27));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    margin: 15px 0 30px 0;
    line-height: 24px;
  }

  a {
    text-decoration: none;
  }
}
