$font-weight-base: 200;
$headings-font-weight:        100 !default;

$white: #FFF;
$black: #000;
//$primary: rgba(22, 148, 123,1);
$primary: rgba(64, 96, 182, 1);
$secondary: rgba(255, 255, 255, 1);
$success: rgba(22, 148, 123,1);

$navbar-brand-padding-y: 16px;
$navbar-nav-link-padding-x: 1rem;
$navbar-light-toggler-border-color: transparent !default;

$light: rgba(255, 255, 255, 0.85) !default;
$dark: rgba(44, 44, 44, 1) !default;

$navbar-light-color:                rgba($black, 1) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .7) !default;
$navbar-dark-color:                rgba($white, 1) !default;
$navbar-dark-hover-color:          rgba($white, .7) !default;
$navbar-dark-active-color:         rgba($white, .7) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1620px
) !default;
