.nav-tabs {

  .nav-link {
    font-weight: 400;
    color: black;

    &:hover,
    &:focus {
      border-color: transparent;
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border: 0 solid transparent;
    border-bottom: 4px solid #1ABC9C;
  }
}