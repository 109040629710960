$var-nav-menu-show: 0.2s;
$var-nav-menu-hide: 0.2s;

#nav-menu {
  opacity: 1;
  -webkit-transition: opacity $var-nav-menu-show ease-in;
  -moz-transition: opacity $var-nav-menu-show ease-in;
  -ms-transition: opacity $var-nav-menu-show ease-in;
  -o-transition: opacity $var-nav-menu-show ease-in;
  transition: opacity $var-nav-menu-show ease-in;

  @include media-breakpoint-up(lg) {
    height: 126px;
  }

  &.menu-hide {
    opacity: 0;
    -webkit-transition: opacity $var-nav-menu-hide ease-out;
    -moz-transition: opacity $var-nav-menu-hide ease-out;
    -ms-transition: opacity $var-nav-menu-hide ease-out;
    -o-transition: opacity $var-nav-menu-hide ease-out;
    transition: opacity $var-nav-menu-hide ease-out;
    /*
    background-color: rgba(255, 255, 255, 0.95) !important;
    -webkit-box-shadow: 0px 6px 14px -2px rgba(0,0,0,0.73);
    box-shadow: 0px 6px 14px -2px rgba(0,0,0,0.73);
     */

    .navbar-toggler {
      background-color: rgba(68, 68, 68, 0.45);
    }

    .navbar-nav {

      @include media-breakpoint-up(lg) {
        //background-color: rgba(255, 255, 255, 0.95) !important;
      }

      .nav-item {
        color: #444444;
      }
    }
  }
}

#nav-menu-fixed {
  height: 84px;
  background-color: $main-bg-color !important;
  -webkit-box-shadow: 0px 4px 14px -2px rgba(0,0,0,0.53);
  box-shadow: 0px 4px 14px -2px rgba(0,0,0,0.53);

  opacity: 0;
  top: -76px;
  -webkit-transition: opacity, top $var-nav-menu-hide ease-out;
  -moz-transition: opacity, top $var-nav-menu-hide ease-out;
  -ms-transition: opacity, top $var-nav-menu-hide ease-out;
  -o-transition: opacity, top $var-nav-menu-hide ease-out;
  transition: opacity, top $var-nav-menu-hide ease-out;


  &:before {
    background-image: linear-gradient(to right, rgba(103, 210, 255, 0.27), rgba(38, 27, 85, 0.27));
  }

  &.menu-show {
    opacity: 1;
    top: 0px;
    -webkit-transition: opacity, top $var-nav-menu-show ease-in-out;
    -moz-transition: opacity, top $var-nav-menu-show ease-in-out;
    -ms-transition: opacity, top $var-nav-menu-show ease-in-out;
    -o-transition: opacity, top $var-nav-menu-show ease-in-out;
    transition: opacity, top $var-nav-menu-show ease-in-out;

    .navbar-toggler {
      background-color: rgba(68, 68, 68, 0.45);
    }

    [class*="container-"] {
      background: $main-bg-color !important;
    }

    .navbar-nav {

      @include media-breakpoint-down(lg) {
        //padding-left: 15px;
        //padding-right: 15px;
      }
    }
  }
}
