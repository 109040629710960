.navbar {
  //border-bottom: solid 1px rgba(255, 255, 255, .3);
  background-color: transparent !important;

  .navbar-nav {

    @include media-breakpoint-down(lg) {
      //background-color: rgba(68, 68, 68, 0.98) !important;
      //border-radius: 15px;
    }

    .nav-item {
      font-weight: 200;
      font: 700 14px/24px "Roboto", sans-serif;
      text-transform: uppercase;
      letter-spacing: .05em;
      //color: #444444;

    }
  }

  .navbar-toggler {
    outline: none;
  }
}
